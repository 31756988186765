.description {
    border: 1px solid rgba(239, 170, 32, 0.50);
    height: auto;
    width: 90%;
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
}

.Attachment {
    height: auto;
    padding: 30px;
    border: 1px solid rgba(239, 170, 32, 0.50);
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.itemdescription {
    border: 1px solid rgba(239, 170, 32, 0.50);
    height: 330px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}