.systemModal .form-control {
  /* background-color: #2B2B40 !important; */
  border: unset !important;
  /* color: rgba(255, 255, 255, 0.50) !important; */
  @apply bg-mainNavy text-mainCustomGray;
}

.systemModal .form-control::placeholder {
  /* color: rgba(255, 255, 255, 0.5) !important; */
  @apply text-mainCustomGray;
}

/* .systemModal .form-control:focus {
  color: rgba(255, 255, 255, 0.5) !important;
} */

/* .systemModal .form-label {
  color: white !important;
} */

.systemModal .css-1ng70dv-control {
  background: #2b2b40 !important;
  background-color: #2b2b40 !important;
  border: unset !important;
}

.systemModal .css-1ng70dv-control:hover {
  background: #2b2b40 !important;
}

.systemModal .css-m1smmt-control:hover {
  background: #2b2b40 !important;
  background-color: #2b2b40 !important;
}

.systemModal .css-m1smmt-control {
  background: #2b2b40 !important;
  background-color: #2b2b40 !important;
  z-index: 11;
}

.systemModal .licenseDate-container {
  width: 75% !important;
}

.systemModal .css-1nmdiq5-menu {
  background-color: #1e1e2d !important;
  color: white !important;
}

.systemModal .edit-header {
  border-radius: 5px;
  border: 1px solid #efaa20;
}

.sumbmitAddUpdateUser {
  width: 139px !important;
  height: 35px !important;
  border-radius: 6px !important;
  background: #efaa20 !important;
  border: unset !important;
  color: white !important;
  font-size: 15px !important;
  line-height: 25.31px;
  font-weight: 500px !important;
}

.edit-header p {
  font-size: 20px;
}
