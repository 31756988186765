.showProjectBorder .form-group {
  width: 100%;
}

.showProjectBorder .form-label {
  color: var(--main-text);
}

.border-golden .dropdown-toggle::after {
  display: none !important;
}
