.pdfbackground {
  width: 53.361px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #151521;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.holidayTypesContainer {
  border-radius: 19px;
  background: var(--main-item-color);
  height: 100%;
  padding: 20px 10px;
}

.allhoildays {
  border-radius: 19px;
  background: #1e1e2d;
  width: 100%;
  height: 100%;
}

.allhoildaysTables {
  height: auto;
  border: 0.962px solid rgba(213, 153, 33, 0.2);
}
