.AllEmployees {
  height: 100%;
  border-radius: 19px;
  @apply bg-mainItems;
  /* background: #1e1e2d; */
}

.EmployeesSystemCard {
  border-radius: 8.667px;
  background: linear-gradient(
    135deg,
    rgba(111, 255, 203, 0.6) 0%,
    rgba(56, 19, 194, 0.6) 100%
  );

  height: 100px;
  z-index: 222222;

  flex-shrink: 0;
}

.allServices {
  border: 0.854px solid rgba(213, 153, 33, 0.5);
  height: 525px;
  overflow-y: auto;
  overflow-x: hidden;
}

.employeeProgress {
  height: 13px !important;
  border-radius: 13px !important;
}

.pdfbackground {
  width: 53.361px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main-navy);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--main-border);
}
