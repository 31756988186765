.css-1n9b5ie .center {
  display: flex !important;
  justify-content: center;
}

.css-ewdv3l:hover {
  background-color: none !important;
}

.golden-text {
  color: #efaa20;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Cairo;
  font-size: 14.287px;
  font-style: normal;
  font-weight: 700;

  /* 242.192% */
}

.goals {
  font-size: 16px;
}

.css-1n9b5ie > .ps-menu-button {
  height: auto !important;
}

.css-1n9b5ie > .ps-menu-button:hover {
  background-color: none !important;
}

.system-card {
  /* width: 85px; */
  overflow: hidden;
  height: 80px;
  border-radius: 12px;
  border: unset !important;
  margin: 15px 0px;
}

.system-card p {
  font-size: 10px;
}

.card__content {
  @apply bg-bgCard;
  /* background: #2B2B40; */
  /* border-radius: 12px; */
  width: 100%;
  height: 100%;
}

.asidePar .progress {
  width: 326px;
  height: 18px;
  border-radius: 13px;
}

.asidePar .user-icon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.aside-icon {
  width: 30px;
  height: 30px;
}

.asidePar .ps-collapsed .hidden-collapsed {
  display: none !important;
}

.asidePar .ps-collapsed .user-icon {
  width: 39px;
  height: 39px;
}

.asidePar .ps-collapsed .system-card {
  width: 58.024px;
  height: 56px;
}

.asidePar .ps-collapsed .system-item {
  margin: 0px !important;
}

.asidePar .ps-collapsed .system-card p {
  font-size: 10px !important;
  margin-top: 0px !important;
}

.asidePar .ps-collapsed .system-card img {
  width: 19.996px;
  height: 20px;
  margin-top: 5px !important;
}

.ps-collapsed .system-card-container .col-md-4 {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.ps-collapsed .collapsed-handler {
  justify-content: center;
}
.ps-collapsed .css-1n9b5ie > .ps-menu-button {
  padding-left: 0px;
  padding-right: 0px;
}
.system-item {
  margin-bottom: 10px;
  margin-top: 10px;
}

.profile-menu-backdrop{
  /* background-color: #efaa20 !important; */
  cursor: auto;
  position: fixed;
  inset: 0;
}

/* .ps-sidebar-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #F5F5F5 !important;


}

.ps-sidebar-container::-webkit-scrollbar {
    width: 7px !important;
    border-top-left-radius: 27px !important;
    border-bottom-left-radius: 27px !important;
    background-color: #F5F5F5 !important;

}


.ps-sidebar-container::-webkit-scrollbar-thumb {
    background-color: #F90 !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    background-image: -webkit-linear-gradient(45deg 
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent)!important;
} */
