.AllRequests {
  width: 100%;
  height: 100%;
  border-radius: 19px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  /* background: #1e1e2d; */
  @apply bg-mainItems;
}
.AllRequests-scroll {
  width: 100%;
  height: 100%;
  border-radius: 19px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  @apply bg-mainItems;
}

.AllRequestsPieChartContainer {
  width: 80% !important;
  /* height: 40% !important; */
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 22px !important;
  border: 1px solid rgba(239, 170, 32, 0.5) !important;
}

.AllRequestsLineChartContainer {
  width: 80% !important;
  /* height: 50% !important; */
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 22px !important;
  border: 1px solid rgba(239, 170, 32, 0.5) !important;
}

.apexcharts-title-text {
  display: none;
}
