/* body{
    background: #151521 ;
} */

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;500&family=Poppins:ital,wght@0,500;1,500;1,600&display=swap ");

.system-bg {
  @apply bg-mainNavy;
  font-family: "Cairo" !important;
  overflow-x: hidden;
  height: auto;
}

.system-logo {
  width: 140px;
  height: 70px;
}

.css-1dimb5e-singleValue {
  color: white !important;
}
.systemModal .modal-content {
  @apply bg-mainItems;
  /* background-color: #1e1e2d !important; */
  /* height: 846px !important; */
  padding: 30px 0px;
  overflow-y: auto;
}
.sumbmitAddUpdateUser {
  width: 139px;
  height: 28px;
  border-radius: 6px;
  background: #efaa20;
  border: unset;
  color: white;
}

.sumbmitAddUpdateUser:hover {
  background-color: var(--main-navy) !important;
  border: 1px solid #efaa20 !important;
  color: var(--main-text) !important;
}

.systemModal .modal-content::-webkit-scrollbar {
  display: none !important;
}

.yellow-scroll::-webkit-scrollbar-track
  .AddProjectComponent::-webkit-scrollbar-track,
.AllRequests-scroll::-webkit-scrollbar-track,
.datatableComponent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5 !important;
  border-top-left-radius: 27px !important;
  border-bottom-left-radius: 27px !important;
}
.yellow-scroll::-webkit-scrollbar,
.AddProjectComponent::-webkit-scrollbar,
.AllRequests-scroll::-webkit-scrollbar,
.datatableComponent::-webkit-scrollbar {
  width: 15px !important;
  border-top-left-radius: 27px !important;
  border-bottom-left-radius: 27px !important;
  background-color: #f5f5f5 !important;
}
.yellow-scroll::-webkit-scrollbar-thumb,
.AddProjectComponent::-webkit-scrollbar-thumb,
.AllRequests-scroll::-webkit-scrollbar-thumb,
.datatableComponent::-webkit-scrollbar-thumb {
  background-color: #f90 !important;

  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;

  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ) !important;
}

.search-users,
.show-employee {
  overflow-x: hidden;
}

.top-3 {
  top: 3%;
}

.end-5 {
  left: 5%;
}

.user-control-model {
  width: 100%;

  height: 88px;
  border: 2px solid #1e1e2d;
}

.ps-menu-button:hover {
  background-color: unset !important;
}

/* .full-system .css-1dimb5e-singleValue {
  color: rgba(255, 255, 255, 0.5) !important;
} */

.system-container {
  min-height: 100vh;
  height: auto;
  width: 100% !important;
}

.full-system .css-m1smmt-control {
  background-color: #1e1e2d !important;
}

.full-system .css-1nmdiq5-menu {
  background-color: #1e1e2d !important;
}

.full-system .css-1nmdiq5-menu {
  color: rgba(255, 255, 255, 0.5) !important;
}

.accordion-button {
  @apply bg-mainNavy;
  width: 250px !important;
  margin-right: auto;
  margin-left: auto;
  height: 40px !important;
  /* background-color: #2b2b40 !important; */
  border: unset !important;
  box-shadow: unset !important;
  outline: unset !important;
  padding: 0px;
  color: var(--main-text) !important;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}

.accordion-collapse.collapse.show,
.accordion-collapse.collapsing {
  width: 250px;
}

.collapsing {
  width: 250px;
}

.accordion-item {
  background-color: unset !important;
  border: unset !important;
  margin-top: 15px;
}

.accordion-body {
  padding: 0px !important;
  border: unset !important;
}

.all-categories .tab {
  @apply bg-mainNavy;
  padding: 2px;
  height: 38px;
  padding: 20px !important;
  padding-right: 20px !important;
  /* background-color: #2b2b40 !important; */
  border-radius: 7px;
  display: flex;
  justify-content: start;
  padding: 3px;
  margin-top: 10px;
  align-items: center;
  color: var(--main-text) !important;
}

.accordion-button::after {
  display: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%234f4f4f%27%3e%3cpath fillRule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}
.accordion-button:not(.collapsed) {
  background-color: unset !important;
}

.accordion-button:hover {
  border: 1px solid rgba(239, 170, 32, 0.5) !important;
}

.full-system .lciOTt {
  background-color: unset !important;
}

.full-system .jWOtlo {
  border: 1px solid #efaa20 !important;
  background: #34344d !important;

  color: white !important;
}

.full-system .golden-border {
  border: 1px dashed #efaa20;
}

.full-system .idxdtx {
  background: #34344d !important;
  color: #efaa20 !important;

  border: 1px solid #efaa20 !important;
}

.full-system .cSRQNB {
  background-color: #1e1e2d;
  color: var(--main-text);
}

.showRequest {
  overflow-x: hidden !important;
  overflow-x: hidden !important;
}

.bXLDzR {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.instrutmentimg {
  width: 93px;
  height: 61px;
  border-radius: 7px;
}

.No-Delete {
  background-color: #a83a3a !important;
  border: unset !important;
}

.No-Delete:focus {
  border: unset !important;
}

.Delete-button {
  background-color: #d59921 !important;
}

.showRequest {
  height: 800px !important;
}

.modal-body {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.showRequestImg .modal-content {
  background-color: transparent !important;
}

.leaveComment textarea {
  background-color: transparent !important;
  border-radius: 7px !important;
  border: 1px solid rgba(239, 170, 32, 0.5) !important;
  box-shadow: unset !important;
}

.leaveComment textarea:focus {
  border-radius: 7px !important;
  border: 1px solid rgba(239, 170, 32, 0.5) !important;
}

.kAfkVG div:first-child {
  overflow: unset !important;
}

.displayFieldSet {
  border: 1px solid rgba(239, 170, 32, 0.5);
}

.full-system .kgGExh {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: unset !important;
}

.full-system .form-label {
  color: white;
}

.systemModal .modal-body {
  /* justify-content: unset !important; */
}

.modal-content .kgGExh {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: unset !important;
}

.modal-content .dkGVyE {
  color: var(--main-text);
  background: #1e1e2d;
}

.modal-content .jiAnER {
  color: var(--main-text);
  background: #34344c;
}

.modal-content .cfvlby {
  background: #34344d !important;
  color: #efaa20 !important;
}

.allowRowEvents {
  width: 100%;
}

.full-system .cfvlby {
  background: #34344d !important;
  color: #efaa20 !important;
}

.full-system .dkGVyE {
  color: var(--main-text) !important;
  background: #1e1e2d !important;
}

.full-system .jiAnER {
  color: var(--main-text);
  background: #34344c;
}

.rbc-off-range-bg {
  background-color: unset !important;
}

.rbc-day-bg.rbc-today {
  border: 1px solid #efaa20 !important;
  background-color: unset !important;
}

.rbc-btn-group {
  color: white !important;
}

.rbc-toolbar button {
  border-radius: unset !important;
}

.rbc-today {
  background-color: unset !important;
}

.rbc-day-bg {
  border-left: 1px solid #ddd;
}

.rbc-event-content,
.rbc-event {
  background-color: #efaa20 !important;
}

.rbc-btn-group button {
  color: white !important;
}

.downloadpdfBtn {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: rgb(27, 27, 27);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
}
.full-system circle {
  color: white;
}
.svgIcon {
  fill: #efaa20;
}
.rdt_Table {
}

.icon2 {
  width: 18px;
  height: 5px;
  border-bottom: 2px solid #efaa20;
  border-left: 2px solid #efaa20;
  border-right: 2px solid #efaa20;
}

.tooltip {
  position: absolute;
  right: -105px;
  opacity: 0;
  background-color: rgb(12, 12, 12);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
}

.tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgb(12, 12, 12);
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  left: -5%;
  transition-duration: 0.3s;
}

.downloadpdfBtn:hover .tooltip {
  opacity: 1;
  transition-duration: 0.3s;
}

.downloadpdfBtn:hover {
  background-color: #efaa20;
  transition-duration: 0.3s;
}

.downloadpdfBtn:hover .icon2 {
  border-bottom: 2px solid rgb(235, 235, 235);
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
}

.downloadpdfBtn:hover .svgIcon {
  fill: rgb(255, 255, 255);
  animation: slide-in-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* system responsive */
@media screen and (max-width: 1400px) {
  .all-categories .tab {
    @apply bg-mainNavy;

    max-width: 100% !important;
    margin-left: auto;
    height: 38px;
    padding: 20px !important;
    /* background-color: #2b2b40 !important; */
    border-radius: 7px;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    align-items: center;
    width: 100%;
    color: var(--main-text) !important;
  }

  /* .search {
    width: 100px !important;
  } */

  .accordion-button {
    @apply bg-mainNavy;

    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    height: 38px !important;
    /* background-color: #2b2b40 !important; */
    border: unset !important;
    box-shadow: unset !important;
    outline: unset !important;
    padding: 0px;
    color: var(--main-text) !important;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    font-size: 14px !important;
  }
  /* .accordion-button a {
    width: 150 !important;
    height: 38px !important;
    background-color: #2b2b40 !important;
    border: unset !important;
    box-shadow: unset !important;
    outline: unset !important;
    padding: 0px;
    color: rgba(255, 255, 255, 0.5) !important;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    font-size: 14px !important;
    width: 100%;
    text-align: start;
  } */
  .accordion-collapse.collapse.show,
  .accordion-collapse.collapsing {
    width: 150px !important;
  }

  .collapsing {
    width: 150px !important;
  }

  .search-users .user-img {
    width: 30px;
    height: 30px;
  }

  .search-users p {
    font-size: 14px !important;
  }

  .search-users .name-header {
    font-size: 14px;
    width: unset !important;
    height: unset !important;
  }

  .accordion-item {
    width: 150px !important;
  }

  .ReviewCasesRequest legend {
    font-size: 14px !important;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* design charts  */
.full-system .apexcharts-menu-item:hover {
  background: #151521 !important;
}
.full-system .apexcharts-menu-item:hover {
  color: #d59921 !important;
}

/* design cleander */

.react-calendar__navigation:focus {
  background-color: transparent !important;
  color: white !important;
}
.react-calendar__navigation__arrow {
  /* display: none; */
  color: var(--main-text) !important;
}
.react-calendar__month-view__weekdays__weekday:focus,
.react-calendar__month-view__days__day:focus {
  color: #efaa20 !important;
  background: var(--main-item-color) !important;
  border-radius: 50% !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
}
.react-calendar__month-view__weekdays__weekday:focus,
.react-calendar__month-view__days__day:focus {
  color: white !important;
  background-color: transparent !important;
}
.react-calendar__month-view__weekdays__weekday:hover {
  color: white !important;
  background-color: transparent !important;
}
.react-calendar__tile:enabled:hover {
  background: var(--main-item-color) !important;
  color: #efaa20 !important;
}
.react-calendar__navigation:hover,
.react-calendar__navigation:focus {
  color: white !important;
  background-color: transparent !important;
}
.react-calendar__navigation__label__labelText,
.react-calendar__month-view__days__day {
  color: var(--main-text) !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #50a191 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--text-gray) !important;
}
/* .react-calendar__tile--active:enabled:hover,
.react-calendar__year-view__months__month:hover {
  color: white !important;
  background-color: transparent !important;
} */
.react-calendar__tile--active:enabled:focus {
  background: transparent !important;
}

.react-calendar__tile--now {
  background: transparent !important;
}
.react-calendar__navigation:hover .react-calendar__navigation:focus {
  background: transparent !important;
}
.react-calendar__navigation__label:hover,
.react-calendar__navigation__label,
.react-calendar__navigation__label:focus {
  background: transparent !important;
}

.react-calendar__navigation button:disabled {
  background: transparent !important;
}
.react-calendar__navigation button:enabled:hover {
  background: transparent !important;
}

/* system according  */
.full-system .all-categories > div {
  width: 100%;
}
.full-system .all-categories {
  width: 100%;
}
.full-system .all-categories .accordion {
  width: 100%;
}
.full-system .accordion,
.full-system .accordion .tab,
.full-system .accordion .tabs a {
  width: 100% !important;
}
.full-system .accordion {
  width: 100% !important;
}
.full-system .accordion-item {
  width: 100% !important;
}
.full-system .accordion-item button {
  max-width: 100% !important;
}
.full-system .apexcharts-canvas {
  margin-top: 15px !important ;
}
.full-system .accordion-item button,
.accordion-item .accordion-collapse,
.accordion-item .accordion-collapse.show {
  width: 100% !important;
}

.full-system .status .dropdown-toggle::after {
  display: none !important;
}
.react-calendar {
  background: none !important;
}
