.ShowClientModal .modal-content {
  width: 80% !important;
}

.ShowClientModal .modal-content {
  overflow-y: auto !important;
}

.edit-clint-header-golden {
  border-radius: 5px;
  border: 1px solid #efaa20 !important;
  color: #efaa20 !important;
}

.edit-clint-header-white {
  border-radius: 5px;
  border: 1px solid var(--main-text) !important;
  color: var(--main-text) !important;
}

.systemModal .lciOTt {
  background-color: unset !important;
}

.systemModal .cSRQNB {
  background-color: #1e1e2d;
  color: var(--main-text);
}

.systemModal .idxdtx {
  background: #34344d !important;
  color: #efaa20 !important;
  border: 1px solid #efaa20 !important;
}
.systemModal .jWOtlo {
  border: 1px solid #efaa20 !important;
  background: #34344d !important;
  color: white !important;
}
