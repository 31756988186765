.addUpdateUser {
  height: 793px;
  border-radius: 27px;
  border: 1px solid #efaa20;
  background: var(--main-item-color) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.addUpdateUser .form-label {
  color: var(--main-text) !important;
}

.addUpdateUser .adduserattchment {
  border-radius: 3.368px;
  border: 0.561px solid #efaa20;
  width: 100%;
  height: 53px;
  margin-right: auto;
  margin-left: auto;
}

.golden {
  color: #efaa20 !important;
}

.small-golden-square {
  height: 147px;
  border: 1px solid rgba(213, 153, 33, 0.2);
}

.addupdateheader {
  color: #efaa20;
  text-align: right;
  font-size: 20px !important;
  font-style: normal;

  line-height: 23.4px;
}

.full-system .form-control {
  /* background-color: #2b2b40 !important; */
  @apply bg-mainNavy;
  color: var(--text-gray);
  border: unset !important;
  /* color: rgba(255, 255, 255, 0.5) !important; */
}

.full-system .form-control::placeholder {
  color: var(--text-gray) !important;
}

/* .full-system .form-control:focus {
  color: rgba(255, 255, 255, 0.5) !important;
} */

.full-system .form-group {
  width: 190px;
}

.full-system .css-1ng70dv-control {
  background: #2b2b40 !important;
  border: unset !important;
}

.full-system .css-1ng70dv-control:hover {
  background: #2b2b40 !important;
}

.addupdateheader .css-m1smmt-control:hover {
  background: #2b2b40 !important;
}

.editUser {
  width: 139px;
  height: 35px;
  border-radius: 6px;
  background: #c60736;
  border: unset;
  color: var(--main-text);
  font-size: 15px;
  line-height: 25.31px;
  font-weight: 500px;
}

.editUser:hover {
  background-color: unset !important;
  border: 1px solid #efaa20 !important;
  color: white !important;
}
