.MainProjects .apexcharts-graphical {
    width: 400px !important;
}

.NestedMainProjects .apexcharts-legend {
    display: none !important;
}

    .showProject .nav-link::after{
        display: none;
    }