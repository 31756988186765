.AddDesignReport {
  border-radius: 25px;
  border: 1px solid #efaa20;
  background: #1e1e2d;
  height: 793px;
}

.AddDesignReport .input-container {
  width: 100%;
}

.AddDesignReport .form-group {
  width: 100%;
}
