.modal-header-from {
  background-color: #ededf6;
}
.dark .modal-header-from {
  background-color: #2b2b40;
}
.modal-body-form {
  background-color: #ffffff;
}
.dark .modal-body-form {
  background-color: #151521;
}
.reply-to {
  background-color: #ededf6 !important;
}
.dark .reply-to {
  background-color: #212131 !important;
}
.comment-form {
  background-color: #ffffff;
}
.dark .comment-form {
  background-color: #2b2b40;
}
.reaction-menu {
  background-color: #d9d9d9;
}
.dark .reaction-menu {
  background-color: rgba(0, 0, 0, 0.7);
}
.reaction-menu-border {
  border-color: #e1a945;
}
.dark .reaction-menu-border {
  border-color: #424242;
}
.reaction-menu-total {
  background-color: #ffffff;
}
.dark .reaction-menu-total {
  background-color: #424242;
}
.reaction-mention-menu {
  background-color: #ededf6;
}
.dark .reaction-mention-menu {
  background-color: #151521;
}
.mention-menu-card {
  background-color: #ededf6;
}
.dark .mention-menu-card {
  background-color: #151521;
}
.mention-menu-card:hover {
  background-color: #e2e2ec;
}
.dark .mention-menu-card:hover {
  background-color: #28283d;
}

.CommentBody {
  background-color: #d9d9d9;
}
.dark .CommentBody {
  background-color: #414162;
}
.CommentBody-border {
  border-top-color: #d9d9d9;
}
.dark .CommentBody-border {
  border-top-color: #414162;
}

.send-arrow-form {
  filter: invert(100%);
}
.dark .send-arrow-form {
  filter: invert(0);
}
.openanimate {
  animation: openanimate 0.5s;
}
.closeanimate {
  animation: closeanimate 0.5s;
}

@keyframes openanimate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
@keyframes closeanimate {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
