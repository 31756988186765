.allhoildays {
  border-radius: 19px;
  background: var(--main-item-color);
  width: 100%;
  height: 100%;
}

.allhoildaysTables {
  height: auto;
  border: 0.962px solid rgba(213, 153, 33, 0.2);
}
