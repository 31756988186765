.addUpdateUser {
  height: 793px;
  border-radius: 27px;
  border: 1px solid #efaa20;
  background: #1e1e2d;
}

.addUpdateUser .form-label {
  color: var(--main-text) !important;
}

.golden {
  color: #efaa20 !important;
}

.small-golden-square {
  height: 147px;
  border: 1px solid rgba(213, 153, 33, 0.2);
}

.addupdateheader {
  color: #efaa20;
  text-align: right;
  font-size: 20px !important;
  font-style: normal;

  line-height: 23.4px;
}

.form-control {
  background-color: var(--main-navy) !important;

  /* background-color: #2B2B40 !important; */
  border: unset !important;
  color: var(--main-text) !important;
}

.form-control::placeholder {
  /* @apply text-mainCustomGray; */
  color: var(--main-text) !important;
}

/* .full-system .form-control:focus {
  color: rgba(255, 255, 255, 0.5) !important;
} */
/* .form-control:focus { */
/* background-color: var(--main-navy) !important; */
/* color: var(--text-gray) !important; */
/* } */
.full-system .form-group {
  width: 190px;
}

.full-system .css-1ng70dv-control {
  background: #2b2b40 !important;
  border: unset !important;
}

.full-system .css-1ng70dv-control:hover {
  background: #2b2b40 !important;
}

.addupdateheader .css-m1smmt-control:hover {
  background: #2b2b40 !important;
}

.sumbmitAddUpdateUser {
  width: 139px;
  height: 35px;
  border-radius: 6px;
  background: #efaa20;
  border: unset;
  color: white;
  font-size: 15px;
  line-height: 25.31px;
  font-weight: 500px;
}

.sumbmitAddUpdateUser:hover {
  background-color: var(--main-navy) !important;
  border: 1px solid #efaa20 !important;
  color: var(--main-text) !important;
}

.editUser {
  width: 139px;
  height: 35px;
  border-radius: 6px;
  background: #c60736;
  border: unset;
  color: var(--main-text);
  font-size: 15px;
  line-height: 25.31px;
  font-weight: 500px;
}

.editUser:hover {
  background-color: unset !important;
  border: 1px solid #efaa20 !important;
  color: white !important;
}
