.AllClientsChart {
  width: 100%;
  height: 100% !important;
  border-radius: 19px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  /* background: #1e1e2d; */
  @apply bg-mainItems;
}

.AllClientsPieChartContainer {
  width: 70% !important;
  height: 40% !important;
  border-radius: 22px !important;
  border: 1px solid rgba(239, 170, 32, 0.5) !important;
}

.AllClinetsLineChartContainer {
  width: 80% !important;

  border-radius: 22px !important;
  border: 1px solid rgba(239, 170, 32, 0.5) !important;
}

.AllRequestsLineChartContainer .apexcharts-title-text {
  display: none;
}
