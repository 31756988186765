.systemLineChart .apexcharts-menu-icon svg {
  fill: #00c4c9 !important;
}

.allDepetmentChart .apexcharts-menu-icon svg {
  fill: #00c4c9 !important;
}

.systemLineChart .progress {
  width: 60px !important;
  height: 6px !important;
}

.deprtament-slider-Container {
  width: 160px !important;
  height: 85.862px !important;
  display: flex;
  align-items: center;
}

.systemDeprtamentSlider .slick-arrow {
  display: none !important;
}

.systemDeprtamentSlider .slick-track {
  height: 100% !important;
  width: 600px !important;
  display: flex;
}

.systemDeprtamentSlider .slick-list {
  width: 100%;
  overflow-x: scroll;
}

.systemDeprtamentSlider img {
  width: 20px;
  height: 20px;
}

.deprtament {
  color: #a0aec0;
  font-size: 14.31px;
}

.systemDeprtamentSlider .main-text {
  color: rgba(255, 255, 255, 0.5);

  font-family: Cairo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.systemDeprtamentSlider .deprtmant-icon {
  border-radius: 6px;
  width: 25px !important;
  height: 25px !important;
}

.systemDeprtamentSlider .sliderProgressBar .progress-bar {
  background-color: #00c4c9 !important;
}

.systemDeprtamentSlider .sliderProgressBar {
  height: 1.98px !important;
}

.systemDeprtamentSlider .sc-crrtmM .sc-dQpIV {
  color: white !important;
}

.choosMainDeprtment .sc-crrtmM {
  color: white !important;
}

.choosMainDeprtment .rdt_Table {
  background: rgba(118, 118, 118, 0.3) !important;
}

.chooseDeprtment .dropdown-toggle::after {
  display: none !important;
}

.apexcharts-xaxis-label tspan {
  fill: var(--main-text) !important;
}

.Main-cleander {
  height: 200px !important;
}
.Main-cleander .react-calendar {
  background-color: var(--main-navy) !important;
  border-radius: 11.105px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: unset !important;
}

.Main-cleander .react-calendar__month-view__weekdays,
.react-calendar__navigation {
  color: white !important;
}

.Main-cleander .react-calendar__tile {
  color: white !important;
}

.Main-cleander
  .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  border-radius: 16.657px !important;
  background: var(--primary-color-1, #4355fa) !important;
  width: 33.314px !important;
  height: 33.314px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.react-calendar {
  background-color: var(--main-navy) !important;
}
