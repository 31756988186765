.ReviewCasesRequest .apexcharts-legend-series {
    display: none !important;
}

.ReviewCasesRequest .apexcharts-tooltip {
    display: none !important;
}
.ReviewCasesRequest legend{
font-size: 16px !important;
}

.reviewChartContainer {
    height: 40%;
    width: 100%;
    border-radius: 19px;
    border: 1px solid rgba(239, 170, 32, 0.50);
}

.TableContainer {
    border-radius: 22px;
    border: 1px solid rgba(239, 170, 32, 0.50);

    width: 98%;



}





.display_project,
.edit_project {
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.reviewChartContainer legend {
    font-size: 16px !important;
}