.all-clients-search-container {
  border-radius: 19px;
  background: var(--main-item-color);
  height: 100%;
}

.all-clients-search-container .dropdown-toggle::after {
  display: none !important;
}

.clint-type {
  color: rgba(255, 255, 255, 0.3);
  font-size: 12.4px;
}

.all-clints-search-driver {
  background: rgba(255, 255, 255, 0.3);
  height: 1px;
  width: 100%;
  margin: 10px 0px 20px 0px;
}

.clintName {
  cursor: pointer;
  margin-bottom: 10px !important;
}

.category-client-Component {
  @apply bg-mainItems;
  height: 100% !important;
  border-radius: 19px;
}

.AllClientPieChartContainer,
.AllLineChartContainer {
  width: 80% !important;
  height: 40% !important;
  border-radius: 22px !important;
  border: 1px solid rgba(239, 170, 32, 0.5) !important;
}

.insideChart .apexcharts-legend {
  display: none !important;
}

.rbc-event {
  color: white !important;
}
