.client-details-Component {
  border-radius: 19px !important;
  background: #1e1e2d !important;
}

.client-details-Component .clinte-details-square {
  border: 1px solid rgba(213, 153, 33, 0.2);
  height: 315px;
  width: 100%;
}

.current_month .modal-content {
  background: var(--cardBg) !important;
  width: 450px !important;
  z-index: 10000;
}

.current_month .modal-content .react-calendar {
  height: 300px !important;
}

.bXLDzR {
  border-radius: unset !important;
}
.hGkspH {
  min-width: unset !important;
}
