.MainSystem {
  @apply bg-mainItems border border-mainBorder;
  border-radius: 19px;
  /* background: #1e1e2d; */
  /* height: 801px; */
  width: 100%;
  overflow: hidden !important;
  position: relative !important;
}

.mainBackground {
  background-image: url(../../../../public//mainBackground.png);
  background-size: 100% 100%;
  opacity: 0.8;
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  animation: rotation 4s infinite linear;
  max-width: 100% !important;
  overflow: hidden;
}

.cardsBackground {
  @apply bg-cardBg;
  border-radius: 16.799px;
  /* background: rgba(47, 46, 70, 0.502); */
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 0px 5px;
  /* z-index: 222222; */
}

.systemCard {
  border-radius: 8.667px;
  background: var(--color-gradient);
  width: 100%;
  height: 84.067px;
  z-index: 222222;

  flex-shrink: 0;
}

.systemLineChart {
  height: 150px;
  border-radius: 17.573px;
  background: #151521;
  width: 95%;
  overflow: hidden;
  margin: auto;
}

.chooseDeprtmentContainer {
  @apply bg-cardBg;

  border-radius: 11.969px;
  /* background: #262639; */
  box-shadow: 0px 0px 4px 0px #00000040;
  height: 100%;
  padding: 10px;
}

.chooseDeprtmentICons {
  width: 115px !important;
  height: 34px;
  border-radius: 8.667px;
  background: var(--color-gradient);
  padding: 5px;
}

.cleanderContainer {
  border-radius: 11.105px;
  background: #262639;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 243px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
