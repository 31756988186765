.chatInputContainer .react-emoji {
  position: static !important;
}
.chatInputContainer .react-emoji-picker--container {
  bottom: 13% !important;
  top: unset !important;
}
.chatInputContainer .react-input-emoji--container {
  border: unset !important;
  direction: rtl !important;
}
.epr-main.EmojiPickerReact > div:last-child,
.epr-emoji-list .epr-emoji-category .epr-emoji-category-label{
  display: none !important;
} 