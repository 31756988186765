.categoryCard {
  border-radius: 8.667px;
  background: #2b2b40;
  /* width: 100%; */
  /* height: 84.067px; */
  /* z-index: 222222; */

  flex-shrink: 0;
}
.subCategoryCard {
  border-radius: 8.667px;
  background: var(--color-gradient);
  z-index: 222222;
  flex-shrink: 0;
}
