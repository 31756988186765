.fieldBorder {
  border: 1px solid rgba(213, 153, 33, 0.2);
}

.fieldBorder legend {
  width: 15% !important;
}

.searchOrder {
  border-radius: 7px;
  background: #2b2b40;
  color: white !important;
}

.searchOrder:hover {
  box-shadow: unset;
  outline: unset;
  border-bottom: unset;
}

.addFileShape {
  width: 94.289px !important;
  height: 91px !important;
  border-radius: 12.06px !important;
  border: 1px dashed #efaa20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chooseFile {
  border-radius: 7.605px;
  background: var(--main-navy) !important;
}
.fieldBorder .form-label {
  color: white !important;
}
