.addproject .form-label {
  color: white !important;
}

.addproject .form-group {
  position: relative !important;
}

.addproject .confirmPayment {
  background-color: #efaa20;
  border: 1px solid #efaa20;
}

.addproject .confirmPayment:hover {
  /* background-color: unset !important; */
  border: 1px solid #efaa20 !important;
}

.addproject .confirmPayment {
  background-color: #efaa20 !important;
  border-color: #efaa20 !important;
}

.addproject .form-group {
  width: 100% !important;
}

.SaveButtonContainer {
  width: 139px;
}

.chooseFile {
  border-radius: 7.605px !important;
  background: var(--main-navy) !important;
  border: unset !important;
}

.fieldBorder .form-label {
  color: white !important;
}
