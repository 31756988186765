/* .mettingModal .css-1dimb5e-singleValue {
    color: var(--main-text) !important;
} */

.mettingModal .ChooseDeprtmant {
  background-color: var(--main-navy) !important;
  /* border-radius: 7px;
background: #2B2B40; */
}

.rbc-day-slot .rbc-time-slot {
  border-top: unset !important;
}

.mettingModal .ChooseDeprtmant:hover {
  background-color: var(--main-navy) !important;
  color: var(--main-text) !important;
}

.ck.ck-reset_all {
  background-color: var(--main-navy) !important;
}

.ck-content {
  background-color: var(--main-navy) !important;
  color: var(--main-text) !important;
  min-height: 200px;
}

.rbc-rtl .rbc-time-content > * + * > * {
  border-right: unset !important;
}

.mettingModal .react-datepicker__triangle {
  display: none !important;
}

.react-time-picker__inputGroup__amPm {
  background-color: unset !important;
}

.react-time-picker__button {
  color: var(--main-text) !important;
}

.react-time-picker__wrapper {
  border: unset !important;
}

/* .rbc-time-slot{

} */
.rbc-time-content {
  border-top: 1px solid #6f6f79 !important;
}

.rbc-time-view {
  border: 1px solid #6f6f79 !important;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #6f6f79 !important;
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-right: 1px solid #6f6f79 !important;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #6f6f79 !important;
}

.rbc-header {
  border-bottom: 1px solid #6f6f79;
}

.rbc-month-view {
  border: 1px solid #6f6f79;
}

.chooseDepartment .modal-content {
  background: #32324d;
  height: 846px !important;
  padding: 30px 0px;
  overflow-y: auto !important;
  width: 600px !important;
}

.ChooseDeprtmant-according {
  height: 574px;
  height: 574px;
  border: 1px solid #efaa20;
  padding: 20px 0px;
  margin: 5px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.ChooseDeprtmant-according .accordion-header {
  display: flex;
  justify-content: center;
}

.ChooseDeprtmant-according .tab {
  padding: 2px;
  height: 38px;
  padding: 20px !important;
  padding-right: 20px !important;
  background-color: #2b2b40 !important;
  border-radius: 7px;
  display: flex;
  justify-content: start;
  padding: 3px;
  margin-top: 10px;
  align-items: center;
  color: rgba(255, 255, 255, 0.5) !important;
}

.ChooseDeprtmant-according .tabs a {
  display: flex;
  justify-content: center;
}

.chooseDepartment .accordion-header .accordion-button {
  padding: 2px;
  height: 38px;
  display: flex !important;
  justify-content: center !important;
  padding: 20px !important;
  padding-right: 20px !important;
  background-color: var(--main-navy) !important;
  border-radius: 7px;
  display: flex;
  justify-content: start;
  padding: 3px;
  margin-top: 10px;
  align-items: center;
  color: rgba(255, 255, 255, 0.5) !important;
}

.ChooseDeprtmant-according .accordion {
  display: flex;
  justify-content: center;
}
.chooseDepartment
  .modal-content
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: white !important;
}
.rbc-time-view {
  /* min-height: 0px !important; */
}
.rbc-time-view::-webkit-scrollbar {
  display: none;
}
.rbc-time-view.rbc-row {
  /* min-height: 0px !important; */
}
