.showRequest {
    height: 800px !important;
}

.modal-body {
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}

.showRequestImg .modal-content {
    background-color: transparent !important;
}

.leaveComment textarea {
    background-color: transparent !important;

    box-shadow: unset !important;
}

.leaveComment textarea:focus {
    border-radius: 7px !important;
    border: 1px solid rgba(239, 170, 32, 0.50) !important; 
}
.full-system  .editIcon{
    width: 51px;
    height: 25px;
}
.show-review .dropdown-toggle::after{
    display: none !important;
}
.show-Design .dropdown-toggle::after{
    display: none !important;
}