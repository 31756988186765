.reportName {
  border-radius: 22px;
  border: 1px solid rgba(239, 170, 32, 0.5);
  height: 119px;
}

.actionIcon {
  width: 51px;
  height: 25px;
}
.discription {
  height: 320px;
  border: 1px solid rgba(239, 170, 32, 0.5);
  margin-top: 30px;
}

.attatchment {
  border: 1px solid rgba(239, 170, 32, 0.5);
  height: 131px;
}
