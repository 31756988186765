.apexcharts-menu-item {
    background-color: #1E1E2D;
}

.apexcharts-menu.apexcharts-menu-open {
    background: unset;
    border: unset !important;
}

.react-datepicker-wrapper::placeholder {
    color: white !important;
}

.project-card {
    color: rgba(255, 255, 255, 0.50);
    cursor: pointer;
    width: 140px;
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 11px;
    border: 1px dashed #434362;
}
.apexcharts-toolbar{
    top: 3px !important;
    
}
.custom-chart .apexcharts-canvas{
    padding-top: 15px;
}
.apexcharts-menu{
    border: none !important;
    padding: 0px !important;
    right: 0px !important;
}