/* Adding fonts */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;700;800;900&family=Poppins:ital,wght@0,500;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;500&family=Poppins:ital,wght@0,500;1,500;1,600&display=swap");
* {
  box-sizing: border-box;
  font-family: "Cairo", sans-serif !important;
}
:root {
  --main-color: #e1b67c;
  --main-navy: #ededf6;
  --main-navyDarker: #dddde0;
  --main-item-color: #ffffff;
  --main-border: #efaa20;
  --main-text: #000000;
  --cardBg: #ffffff80;
  --main-text-reverse: #ffffff;
  --bg-item: #f6f6f6;
  --text-gray: #2e2e2eba;
  --button-gray: #a2a2a280;
  --color-gradient: linear-gradient(135deg, #6fffcb 0%, #3813c2 100%);
  --yellowWhite: #000000;
  --clockBg: #ededf6;
}
.dark {
  /* Add dark theme colors  */
  --main-navy: #151521;
  --main-navyDarker: #11111b;
  --main-item-color: #1e1e2d;
  --main-border: #2b2b40;
  --main-text: #ffffff;
  --main-text-reverse: #000000;
  --cardBg: rgba(47, 46, 70, 0.5);
  --bg-item: #2b2b40;
  --text-gray: #ffffff4d;
  --button-gray: #d9d9d980;
  --color-gradient: linear-gradient(
    135deg,
    rgba(111, 255, 203, 0.6) 0%,
    rgba(56, 19, 194, 0.6) 100%
  );
  --yellowWhite: #efaa20;
  --clockBg: #414162;
}
.textArea {
  resize: none !important;
}
.technicalSupportTab {
  @apply bg-mainNavy;
  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--main-text) !important;
}

.customModel {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.customRedButton {
  @apply mt-2 py-2 w-[140px] font-semibold text-sm text-white  bg-[#A83A3A] rounded-lg hover:bg-[#9d3636] flex gap-2 items-center justify-center;
}

.react-international-phone-input-container .react-international-phone-input {
  background-color: var(--main-navy) !important;
  color: var(--main-text) !important;
  border: none !important;
}
.react-international-phone-country-selector-button:hover {
  background: none !important;
}
.react-international-phone-country-selector-button {
  border: none !important;
  background-color: var(--main-navy) !important;
}
* {
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--main-navy);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #efaa20;
  }
}
.customBG {
  background: var(--main-item-color) !important;
}
.customButton {
  @apply px-10 py-1  rounded-md;
  background: #efaa20;
  color: white !important;
  /* EFAA20 */
}

.react-calendar__tile--now.react-calendar__tile--active {
  color: #efaa20 !important;
  background: var(--main-item-color) !important;
  border-radius: 50% !important;
}
.react-calendar__tile--active:hover {
  color: #efaa20 !important;
  background: var(--main-item-color) !important;
  border-radius: 50% !important;
}
.react-calendar__tile--active:focus {
  color: #efaa20 !important;
  background: var(--main-item-color) !important;
  border-radius: 50% !important;
}
.react-calendar__month-view__weekdays__weekday:hover {
  color: var(--main-text) !important;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none !important;
  cursor: auto !important;
}
.borderYellow {
  @apply border border-mainBorder;
}
.cardBorder {
  @apply border border-dashed border-[#434362] hover:border-[#efaa20] transition-colors;
}
.fontWhite {
  @apply text-mainText text-xs;
}
.fontWhiteImportant {
  color: var(--main-text);
}
.calendar__navigation__label {
  background: transparent !important;
}
.textColor {
  color: var(--main-text) !important;
}
.react-calendar__month-view__weekdays {
  @apply border-b border-mainText mx-4;
}
.react-calendar__month-view__weekdays__weekday abbr {
  @apply no-underline text-mainText;
}
.react-calendar__month-view__weekdays__weekday--current {
  @apply text-mainText;
}

.newBorder {
  @apply border border-mainBorder;
}
.filterItem {
  @apply border  rounded-md hover:border-transparent  border-[#EFAA20] bg-mainItems;
}
.apexcharts-text tspan,
.apexcharts-text title,
.apexcharts-xaxis-label tspan,
.apexcharts-xaxis-label title {
  color: var(--main-text) !important;
}
.css-qnb552.ps-rtl {
  z-index: 0 !important;
}
body {
  direction: rtl !important;
  overflow-y: auto !important;
  overflow-x: none !important;
  font-family: "Cairo", sans-serif !important;
  scroll-behavior: smooth;
  max-width: 100% !important;
  margin: 0px;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.collapse {
  visibility: unset !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.navbar {
  z-index: 100 !important;
}

button {
  box-shadow: unset !important;
}

.stepper .modal-content {
  overflow: auto !important;
}

.home-layer {
  background-color: rgba(0, 35, 82, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.border-golden {
  border-radius: 19px;
  border: 1px solid rgba(239, 170, 32, 0.5);
}

.Main-background {
  background-color: var(--main-color) !important;
}

.Home-section {
  background-image: url("../public/HomeBackground.jpg");
  background-size: 100% 100%;
  height: 80vh !important;
}

.navbar.hidden {
  top: -80vh !important;
}

.main-text {
  color: #717174;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.163px;
}

.submit-btn {
  border-color: unset !important;
}

.Design-Step-one .dropdown button {
  width: 100% !important;
}

.modal button:disabled {
  opacity: 0.3 !important;
  cursor: auto !important;
}

.rotate-image {
  width: 70%;
}

.Design-Step-one .dropdown a {
  display: flex;
  justify-content: center;
}

.Design-Step-one .btn-primary {
  background-color: #e1dfdf !important;
  border: unset !important;
  color: black !important;
}

input {
  font-size: 14px !important;
}

.z-index-1 {
  z-index: -1 !important;
}

a {
  text-decoration: none !important;
  /* color: unset !important; */
}
a:hover {
  color: var(--main-text) !important;
}

.main_color {
  color: var(--main-color) !important;
}

.navbar-toggler {
  border: unset !important;
}

.f-grey {
  color: rgb(144, 144, 144) !important;
}

.form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc4c64%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc4c64%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
}

a {
  scroll-behavior: smooth;
}

.navbar-toggler {
  text-decoration: none !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: unset !important;
  box-shadow: unset !important;
}

.w-90 {
  width: 90% !important;
}

.nav-link {
  font-weight: 500;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  color: black;
  text-transform: lowercase;
  transition: all 0.6s ease-in-out;
}

.dropdown-toggle::after {
  display: none !important;
  margin-left: 0.5em !important;
}

.status .dropdown-toggle::after {
  display: none !important;
}

.slider-Container img {
  border-radius: 10px;
}

.btn-danger {
  background-color: #dc3545 !important;
}

.btn-danger:hover {
  background-color: white !important;
  color: #dc3545 !important;
}

.h-100px {
  height: 100px;
}
.h-90 {
  height: 90%;
}

.landing button {
  padding: 10px 25px;
  border: unset;
  border-radius: 15px;
  color: white;
  z-index: 1;
  background: #b6ac9c;
  opacity: 50;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  transition: all 250ms;
}

.stepper div div button {
  padding: 10px 25px;
  border: unset;
  border-radius: 15px;
  color: white;
  z-index: 1;
  background: #b6ac9c;
  opacity: 50;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  transition: all 250ms;
}

.click_me {
  z-index: 5;
}

.click_me::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #fbc268;
  z-index: -1;

  transition: all 250ms;
}

.click_me:hover {
  color: #e8e8e8;
}

.click_me:hover::before {
  width: 100%;
}

.nav-link::before {
  position: absolute;
  content: "";
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  right: 0;
  bottom: -10px;
  z-index: -11;
  height: 2px;
  width: 0;
}

.item {
  margin-top: 30px;
  margin-bottom: 30px;
}

.nav-link:hover::before {
  transition: all 0.4s ease-in-out;
  width: 100%;
  margin: auto;
  height: 1px;
  background-color: var(--main-color);
}

.slick-arrow::before {
  background-color: transparent !important;
  opacity: 25%;
  color: black !important;
}

.slick-prev::before {
  background-color: transparent !important;
  opacity: 25%;
  color: black !important;
}

.slick-next::before {
  background-color: transparent !important;
  opacity: 25%;
  color: black !important;
}

.slick-arrow::after {
  background-color: transparent !important;

  color: black !important;
}

.collapsed {
  transition: 0.4s ease-in-out;
}

.slider-Container {
  width: 350px !important;
  height: 350px !important;
  margin-right: 20px;
  margin-left: 20px;
}
.client-section .slider-Container {
  width: 250px !important;
  height: 250px !important;
  margin-right: 20px;
  margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  overflow: hidden;
}
.slider-Container span {
  width: 100%;
  height: 100%;
}

.slider-Container img {
  border-radius: 10px;
}

.default-color {
  color: var(--main-color);
}

.main-color {
  background-color: var(--main-color) !important;
  color: white !important;
}

.main-color:hover {
  background-color: white !important;
  color: var(--main-color) !important;
}

.main-bg-color {
  background-color: white !important;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color);
}

.main_color {
  color: var(--main-color);
}

.slick-dots li button {
  font-size: 20px !important;
  /* color: var(--main-color) !important; */
}

.slick-dots li button::before {
  font-size: 20px !important;
}

.bg-main-color {
  background-color: var(--main-color) !important;
  height: 140px;
  width: 100%;
  bottom: -3%;
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.map-container .card {
  align-items: center;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.card-container .card {
  flex-direction: column !important;
  align-items: center;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  border-top-left-radius: unset !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: unset !important;
}

.map-container {
  z-index: 9;
}

/* .copy-right {
  z-index: -1;
} */

.copy-right-text::before {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 50%;
  height: 1px;
  background-color: var(--main-text);
  width: 200px;
  max-width: 100% !important;
}

.copy-right-text::after {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 50%;
  height: 1px;
  background-color: var(--main-text);
  width: 200px;
  max-width: 100% !important;
}

.pro-btn {
  padding: 5px !important;
}

.line-before::before {
  content: "";
  position: absolute;
  top: 44%;
  right: -3%;
  height: 126px;
  width: 1.5px;
  background-color: var(--main-color);
}

.WhoWeAreshape {
  top: -5% !important;
}

/* create project model style */

@import url(https://fonts.googleapis.com/css?family=Montserrat);

/* 
.CreateProject-Model {
    height: 40%;
    width: 80%;
    position: absolute;
    top: 20%;
    left: 5%;
    z-index: 99 ;
    background: #6441A5; 
    background: -webkit-linear-gradient(to left, #6441A5, #2a0845);
} */

/* .CreateProject-Model {
    font-family: montserrat, arial, verdana;
    background: transparent;
} */

/*form styles*/

ol.progtrckr {
  margin: 0;
  padding-bottom: 2.2rem;
  list-style-type: none;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  padding: 0 0.7rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #33c3f0;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #33c3f0;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #0fa0ce;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #33c3f0;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #0fa0ce;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #33c3f0;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #0fa0ce;
}

/* choice card */
.choice-card {
  width: 250px;
  height: 300px;
  border-radius: 30px;
  background: #f9f6f6;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
}

.choice-card:hover {
  cursor: pointer;
}

.go2392553372 {
  /* display: none !important; */
  width: 100% !important;

  justify-content: center;
  display: flex;
}

.modal-body {
  position: relative;
}
.carousel-indicators button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
  background-color: #efaa20 !important;
}
.carousel-overlay {
  background: linear-gradient(to top, #000, transparent);
  opacity: 0.3;
  position: absolute;
  inset: 0px;
}
.submit-poper {
  background: rgb(225, 182, 124) !important;
  text-align: center !important;
  padding: 10px 20px !important;
  border: unset;
  border-radius: 15px;
  color: white;
  font-weight: 1000;
  font-size: 17px;
  width: 100% !important;
  height: 50px !important;

  bottom: 7%;
}

.modal-body > div > div {
  /* display: flex;
  justify-content: center; */
}

.modal-body > div > div button {
  /* cursor: pointer !important;
  z-index: 0; */
}

.modal-header .btn-close {
  width: 20px;
  margin: unset !important;
}

.modal-header {
  display: flex !important;
  justify-content: end !important;
  border-color: #efaa20 !important;
}

.pointer {
  cursor: pointer;
}

.socail-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socail-icons {
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  height: 300px !important;
}

/* multi steps css */
.stepper .modal-content {
  height: 650px !important;
}

.modal-content {
  /* border-radius: 25px !important; */
  background: var(--main-item-color) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* border: 1px solid #efaa20 !important; */
  /* border-radius: 8px !important; */
  /* padding: 1rem !important; */
  min-width: 350px !important;
  /* margin: auto !important; */
  @apply shadow-2xl;
}

.modal-body ol {
  justify-content: space-evenly !important;
}

.licenseDate-container .react-datepicker-wrapper {
  width: 100% !important;
}

.modal-body ol li {
  background-color: #e1dfdf;
  text-align: center;
  border-radius: 20px;
  color: black !important;
  display: flex;
  justify-content: center;
  width: 115px !important;
  margin: 7px;
  height: 70px;
  border-radius: 8px;
  border: unset !important;
  font-size: 9px !important;
}

.modal-body ol li span {
  text-align: center;
  font-size: 12px !important;
  overflow: hidden;
}

.modal-body ol li::before {
  display: none !important;
}

.modal-body .go2150698616.go433304200 {
  background-color: #c9a56c;
  color: var(--main-text) !important;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

@media screen and (max-width: 1200px) {
  .nav-link {
    margin-left: 10px !important;
  }

  .slugon {
    font-size: 22px !important;
  }

  .WhoWeAreshape img {
    width: 350px !important;
    height: 350px !important;
  }

  .modal-body > div > div {
    /* margin-top: 30px; */
  }

  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  .modal-dialog {
    width: 500px !important;
  }

  .modal-dialog .choice-card {
    width: 200px !important;
  }

  .dropdown-menu[data-bs-popper] {
    right: 0 !important;
  }

  .drop .socail-icons a svg {
    width: 20px !important;
    height: 20px !important;
  }

  .nav-link:hover {
    color: var(--main-color) !important;
  }

  .line-before::before {
    top: 39% !important;
  }

  .copy-right-text::before {
    width: 100px !important;
  }

  .modal-body ol li span {
    text-align: center;
    font-size: 12px !important;
  }

  .copy-right-text::after {
    width: 100px !important;
  }

  .main-color:hover {
    background-color: white !important;
    color: var(--main-color) !important;
  }

  .modal-body > div > div button {
    /* margin-left: 5px !important;
    margin-top: 10px; */
  }

  .main-bg-color {
    background-color: white !important;
    color: var(--main-color) !important;
    border: 1px solid var(--main-color);
  }

  .main_color {
    color: var(--main-color);
  }

  .slider-Container {
    width: 280px !important;
    height: 280px !important;
  }

  .setting-nav {
    flex-direction: column !important;
    gap: 20px !important;
    margin-right: 20px;
  }

  .modal-dialog .choice-card {
    height: 225px !important;
  }

  .Home p {
    font-size: 16px !important;
  }

  .nav-link::before {
    display: none;
  }

  .rounded-wave {
    display: none !important;
  }

  .setting-nav a {
    color: black !important;
  }

  .WhoWeAreshape {
    top: 8% !important;
  }
}

@media screen and (max-width: 760px) {
  .dropdown > a {
    color: black !important;
    margin-left: 20px !important;
  }

  .TypeAnimation {
    width: 75% !important;
  }

  .slugon {
    font-size: 16px !important;
  }

  .WhoWeAreshape {
    top: 0% !important;
  }

  .modal.fade.show {
    display: flex !important;
    justify-content: center !important;
  }

  .line-before::before {
    top: 45% !important;
    right: -2% !important;
    height: 90px !important;
    width: 1px !important;
  }

  .setting-nav {
    flex-direction: column !important;
    gap: 20px !important;
    margin-right: 20px;
  }

  .nav-link {
    color: black !important;
  }

  .slick-dots li button {
    width: 10px !important;
    height: 10px !important;

    /* background: transparent; */
  }

  .modal-body ol {
    display: flex;
  }

  .map-container .card {
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  .nav-link::before {
    display: none !important;
  }

  .flex-col-sm {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  .sm-fs {
    font-size: 14px !important;
  }

  .sm-header {
    font-size: 20px !important;
  }

  .sm-par {
    font-size: 15px !important;
  }

  .slider-Container {
    width: 150px !important;
    height: 150px !important;
  }

  .sm-justify-center {
    justify-content: center !important;
  }

  .bg-right {
    display: none;
  }

  .md-align-start {
    align-items: start !important;
  }

  .d-none-sm {
    display: none;
  }

  .modal-dialog {
    width: 270px !important;
  }

  .modal-dialog .choice-card {
    width: 170px !important;
  }

  .copy-right-text::before {
    right: -3% !important;
    width: 20px !important;
    bottom: 48% !important;
    width: 28px !important;
    max-width: 100% !important;
  }

  .copy-right-text::after {
    left: -3% !important;
    width: 20px !important;
    bottom: 48% !important;
    width: 28px !important;
    max-width: 100% !important;
  }

  .stepper .modal-body ol li {
    background-color: #e1dfdf;
    text-align: center;
    border-radius: 20px;
    /* width: 100%; */
    color: black !important;
    display: flex;
    justify-content: center;
    width: 70px !important;
    margin: 7px;
    height: 70px;
    border-radius: 50%;
    border: unset !important;
    font-size: 7px !important;
    align-items: center;
  }

  .stepper .modal-body ol li span {
    font-size: 10px !important;
    white-space: unset !important;
  }

  .stepper .go2150698616 {
    line-height: unset !important;
  }

  /* .modal-body ol li::after {
    content: "1"
  } */

  .stepper .go2150698616 {
    min-width: unset !important;
  }
}

#regForm {
  background-color: #ffffff;
  margin: 100px auto;
  padding: 40px;
  width: 70%;
  min-width: 300px;
}

.Home-services .card {
  box-shadow: unset !important;
}

.card-flibing .card {
  perspective: 1000px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-flibing .card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1.5s;
}

.card-flibing .card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-flibing .card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-flibing .card-front {
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  /* border: 10px solid #6A2C70; */
  /* box-shadow: 15px 15px 30px #bebebe,
    -15px -15px 30px #ffffff; */
  box-shadow: 15px 15px 30px #bebebe;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
}

.card-flibing .card-back {
  /* background-color: #F08A5D; */
  color: black;
  box-shadow: 15px 15px 30px #bebebe;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(180deg);
}

.step-three + div button:disabled {
  display: none !important;
}

.react-international-phone-country-selector {
  width: 25% !important;
  margin: 0;
}

.react-international-phone-country-selector-button {
  width: 100% !important;
}

.react-international-phone-input {
  width: 85% !important;
}

.export-bg {
  outline: unset !important;
  box-shadow: unset !important;
}

/* system style */
.submitSystemPoper {
  display: flex !important;
  align-items: center;
  height: 100%;
}

.submitSystemPoper .modal-content {
  width: 647px;
  border-radius: 20.266px !important;
  border: 1px solid #efaa20 !important;
  display: flex;
  flex-direction: column;
  height: 306px !important;
  background: var(--main-item-color) !important;
}

/* .submitSystemPoper .modal-content div div {
  width: 90% !important;
  text-align: center;
} */

/* .submitSystemPoper .css-1dimb5e-singleValue {
  color: white !important;
} */

/* *::-webkit-scrollbar-track {


  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);




}

*::-webkit-scrollbar {
  width: 10px;
  border-radius: 7px !important;
  border-radius: 22px;

  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  background-color: #F90;
  border-radius: 22px;

  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, .2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, .2) 50%,
      rgba(255, 255, 255, .2) 75%,
      transparent 75%,
      transparent)
}
 */

legend {
  width: 30% !important;
  float: unset !important;
  color: var(--main-text);
  padding: 3px 6px;
  font-size: 20px !important;
}

.bzUdFx {
  justify-content: center !important;
}

.gnBnkV {
  justify-content: center;
}

/* .slider-Container{
width: 250px !important;
height: 250px !important;
} */
@media screen and (max-width: 1280px) {
  .main-text {
    font-size: 14px !important;
  }

  /* .react-datepicker__input-container {
    width: unset !important;
  } */
}

@media screen and (max-width: 760px) {
  .main-text {
    font-size: 14px;
    font-style: normal;
  }
}

/* .editUser {} */

.rbc-row-content {
  color: var(--main-text);
}
.rbc-row-content h2 {
  text-align: right;
}
.rbc-day-bg {
  box-sizing: border-box;
  /* border: 1px solid #ffffff33; */
}
.rbc-day-bg:hover {
  border-color: #efaa20 !important;
}

.rbc-btn-group button:hover,
.rbc-btn-group button:active,
.rbc-btn-group button:focus,
.rbc-active {
  background-color: #efaa20 !important;
  border-color: #efaa20 !important;
}
.rbc-allday-cell {
  background-color: var(--main-item-color) !important;
}
.rbc-current-time-indicator {
  display: none !important;
}
.ck-editor {
  overflow: hidden;
  border-radius: 7px;
}
.ck-editor p {
  max-width: 69vw !important;
  overflow: hidden;
}
.ck.ck-toolbar {
  border: none;
}
.ck-toolbar {
  background-color: var(--main-navy) !important;
  color: var(--main-text) !important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: none;
  border-top: 1px solid #ffffff8a;
}
.ck.ck-toolbar__separator {
  background-color: transparent !important;
}
.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--main-text);
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: transparent;
}

.MuiPickersPopper-paper {
  direction: ltr;
}
.date-input input,
.date-input label,
.date-input button {
  color: var(--main-text);
}
.css-1jbpibk-MuiFormControl-root-MuiTextField-root .input {
  color: var(--main-text) !important;
}

.custom-accordion-item {
  width: 100% !important;
}
.custom-accordion-item button {
  max-width: 100% !important;
}
.custom-accordion-item button,
.custom-accordion-item .accordion-collapse,
.custom-accordion-item .accordion-collapse.show {
  width: 100% !important;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #efaa20;
  border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}
.ck.ck-editor__main {
  color: var(--main-text); /* Example background color */
}
.MuiMenu-paper {
  background-color: #414162 !important;
}
.MuiMenu-paper li {
  color: rgba(255, 255, 255, 0.5);
}
.meeting .rbc-time-content::-webkit-scrollbar {
  display: none;
}
.rbc-time-header {
  margin-right: 0px !important;
  background: rgb(22, 22, 34);
}
.rbc-time-header .rbc-label {
  display: none;
}

.modal-backdrop {
  background: transparent !important;
}

.Toastify__toast {
  background-color: var(--main-item-color) !important;
}
.Toastify__toast button {
  color: var(--main-text);
}

.notification-dropdown .dropdown-toggle::after {
  display: none;
}
.rbc-button-link {
  color: var(--main-text);
}
.rbc-off-range .rbc-button-link {
  color: var(--text-gray);
}
.ck-button svg {
  color: var(--main-text) !important;
}
.ck .ck-button__label {
  color: var(--main-text) !important;
}
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value,
.apexcharts-datalabels,
.apexcharts-pie-label {
  fill: var(--main-text) !important;
}

.rbc-btn-group:first-child button:first-child {
  display: none;
}
.rbc-btn-group {
  border-radius: 6px !important;
}
.rbc-toolbar button {
  border: none !important;
  color: var(--main-text) !important;
  background: var(--main-navy) !important;
  font-size: 18px;
}
.rbc-toolbar span:last-child button:last-child {
  display: none;
}
.rbc-toolbar span:last-child button:nth-child(2) {
  display: none;
}
.rbc-toolbar-label {
  font-size: 25px;
  font-weight: 500;
  color: var(--main-text);
}
.rbc-header {
  color: var(--main-text) !important;
}
.rbc-event {
  border-radius: unset !important;
}

.apexcharts-legend::-webkit-scrollbar-thumb {
  background: #efaa20 !important;
}

.ck.ck-button:active {
  background: var(--main-navy) !important;
  border: none !important;
}
.ck.ck-button:active,
.ck.ck-button:focus,
a.ck.ck-button:active,
a.ck.ck-button:focus {
  border: none !important;
  /* background: #efaa20 !important; */
}
.ck.ck-dropdown__panel,
.ck.ck-list {
  background: var(--main-navy) !important;
}

.ck.ck-list__item .ck-button:hover,
.ck-button.ck-on {
  background: #efaa20 !important;
  color: var(--main-text) !important;
}
.ck.ck-input {
  background: var(--main-navy) !important;
  color: var(--main-text) !important;
}
.ck.ck-labeled-field-view
  > .ck.ck-labeled-field-view__input-wrapper
  > .ck.ck-label {
  background: var(--main-navy) !important;
}

.searchInputPlaceholder::placeholder {
  color: var(--main-text) !important;
}

.searchBar input::placeholder {
  color: var(--main-text) !important;
}

.rbc-month-row {
  z-index: 2 !important;
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: var(--main-navy) !important;
}

.clockContainer {
  width: 300px !important;
  height: 300px !important;
  background: var(--main-item-color) !important;
  border: 4px solid #efaa20 !important;
  border-radius: 50% !important;
}
.react-clock__hour-mark__body {
  height: 150px !important;
  width: 1.5px !important;
  background-color: var(--clockBg) !important;
  z-index: 0 !important;
}
.react-clock__mark__number {
  color: var(--main-text) !important;
  z-index: 100 !important;
  font-weight: 700 !important;
  top: 0% !important;
  left: -42px !important;
}
.react-clock__hand__body {
  background: #efaa20 !important;
  width: 5px !important;
  clippath: polygon(74% 13%, 100% 50%, 69% 84%, 0% 50%);
  transform: translateX(36%);
  bottom: 43% !important;
}
.active-day {
  background-color: #efaa20 !important; /* Highlight color for active days */
  color: white !important; /* Text color for active days */
  border-radius: 50%; /* Make the highlight circular */
}
.react-clock__face {
  border-color: transparent !important;
}
.mention-form {
  width: 100% !important;
  height: 100% !important;
  /* background-color: var(--main-item-color) !important; */
}
.mention-form__suggestions {
  background-color: var(--main-item-color) !important;
}
.mention-form textarea {
  outline: none;
  /* background-color: var(--main-item-color) !important; */
}
.mention-form .mention-form__control {
  width: 100% !important;
}

.custom-progress-bar {
  /* border-radius: 99999% !important ; */
  height: 20px;
}
.custom-progress-bar .progress-bar {
  height: 20px;
}
