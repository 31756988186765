.systemLoginmorning {
  /* background-image: url("../../../../../public/SystemBackGrounds/systemLoginmorning.png"); */
  background-image: url("https://bsa2011.com/images/systemLoginLight.png");
  background-size: 100% 100%;
}

.systemLoginNight {
  /* background-image: url("../../../../../public/SystemBackGrounds/systemLoginnight.jpg"); */
  background-image: url("https://bsa2011.com/images/systemLoginDark.png");
  background-size: 100% 100%;
}

.systemLogin .signInCard {
  box-sizing: border-box;
  width: 580.858px !important;
  /* height: 600px !important; */
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid white;
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(1px);

  text-align: center;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bolder;
  color: black;
}

.systemLogin .form-label {
  float: right !important;
}

@media screen and (max-width: 760px) {
  .signInCard {
    width: 300px !important;
    height: 500px !important;
  }

  .signInCard img {
    width: 200px !important;
    height: 150px !important;
  }

  .signInCard button {
    width: 150px !important;
    height: 50px !important;
  }
}
