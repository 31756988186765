/* .addHoliday .css-1dimb5e-singleValue{
    color: white !important ;
} */
.addHoliday .form-group{
    width: 100% !important;
}
.addHoliday .modal-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-top-left-radius: 27px;
    border-bottom-left-radius: 27px;

}

.addHoliday .modal-content::-webkit-scrollbar {
    width: 15px;
    border-top-left-radius: 27px;
    border-bottom-left-radius: 27px;

    background-color: #F5F5F5;

}

.addHoliday .modal-content::-webkit-scrollbar-thumb {
    background-color: #F90;
   
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent)
}