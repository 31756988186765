.AddProjectComponent {
  border-radius: 27px;
  border: 1px solid #efaa20;
  /* background-color: #1E1E2D; */
  height: 793px !important;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  @apply bg-mainItems;
}

.addproject .form-label {
  color: white !important;
}

.addproject .form-group {
  position: relative !important;
}

.addproject .confirmPayment {
  background-color: #efaa20;
  border: 1px solid #efaa20;
}

.addproject .confirmPayment:hover {
  /* background-color: unset !important; */
  border: 1px solid #efaa20 !important;
}

.addproject .confirmPayment {
  background-color: #efaa20 !important;
  border-color: #efaa20 !important;
}

.addproject .form-group {
  width: 100% !important;
}

.SaveButtonContainer {
  width: 139px;
}
