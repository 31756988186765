.AddProject {
  width: 100%;
  height: 820px !important;
  border-radius: 27px;
  border: 1px solid #efaa20;

  /* background: #1e1e2d; */
  @apply bg-mainItems;
}

.add-project-header {
  font-size: 20px;
}

.AddProject div > div button:first-child {
  display: none;
}

.addProjectStep {
  border: 1px solid rgba(213, 153, 33, 0.2);
  height: 60%;
  width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.AddProject .form-group {
  width: 100% !important;
}

.AddProject .form-label {
  @apply text-mainText;
}

.AddProject div > div button:last-child {
  width: 139px !important;
  height: 35px !important;
  border-radius: 6px !important;
  background: #efaa20 !important;
  border: unset !important;
  color: #fff !important;
  font-size: 15px !important;
  line-height: 25.31px;
  text-align: center;
  float: left;
  margin: 20px 40px;
  font-weight: 500px !important;
}
/* .AddProject div > div button:first-child {
  width: 139px !important;
  height: 35px !important;
  border-radius: 6px !important;
  background: #efaa20 !important;
  border: unset !important;
  color: #1e1e2d !important;
  font-size: 15px !important;
  line-height: 25.31px;
  text-align: center;
  float: right;
  margin: 20px 40px;
  font-weight: 500px !important;
} */

/* .AddProject div div>div {
    display: flex;
    background-color: red;


} */
.AddProject .go2150698616:hover {
  color: #efaa20 !important;
}

.AddProject .licenseDate-container {
  width: 100% !important;
}

/* 
.AddProject .go169520481 {} */

.go433304200:before {
  background-color: #efaa20 !important;
}

.go3842760039:before {
  content: "•";
  color: white;
  background-color: #efaa20 !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background-color: #2b2b40 !important;
}

.css-t3ipsp-control:hover {
  background-color: #2b2b40 !important;
}

.AddProject .go2150698616.go433304200 {
  background-color: none !important;
}

.AddProject .go2150698616 span:hover {
  color: #efaa20 !important;
}

.AddProject .go2150698616.go3842760039 span {
  color: #efaa20 !important;
}

.AddProject .react-international-phone-country-selector-button {
  display: unset !important;
}
.react-international-phone-input-container {
  display: flex;
  flex-direction: row-reverse;
}
.AddProject .react-international-phone-input {
  background: var(--main-navy) !important;
  border: unset !important;
  color: var(--main-text) !important;
  border-radius: 0px 7px 7px 0px !important;
}

.AddProject .react-international-phone-country-selector-button {
  background-color: var(--main-navy) !important;
  border: unset !important;
}

.AddProject button:disabled {
  opacity: 0.6;
}

.AddProject .choose-file-input {
  width: 250px !important;
  height: 36px !important;
}

.AddProject .go3842760039:before {
  content: "1";
  background-color: unset !important;
  width: 53px !important;
  height: 53px !important;
  border: 1px solid #efaa20 !important;
  background-color: #efaa20 !important ;
  font-family: "Cairo" !important;
  font-size: 30px !important;
}
.AddProject .go3842760039 span {
  display: none !important;
}
.AddProject .go2150698616 span {
  display: none !important;
}
.AddProject .go2150698616:before {
  bottom: -50% !important;
}

.AddProject .go3842760039:before {
  content: "1";
  background-color: unset !important;
  width: 53px !important;
  height: 53px !important;
  border: 1px solid #efaa20 !important;
  background-color: #efaa20 !important ;
  font-family: "Cairo" !important;
  font-size: 30px !important;
}

.AddProject ol li:nth-child(1)::before {
  content: "1";
  background-color: #efaa20;
  width: 53px !important;
  height: 53px !important;
  border: 1px solid #efaa20 !important;
  color: white !important;
  font-family: "Cairo" !important;
  font-size: 30px !important;
}

.AddProject ol li:nth-child(2)::before {
  content: "2";
  background-color: #efaa20;
  width: 53px !important;
  height: 53px !important;
  border: 1px solid #efaa20 !important;
  color: white !important;
  font-family: "Cairo" !important;
  font-size: 30px !important;
  /* @apply text-mainText; */
}

.AddProject ol li:nth-child(3)::before {
  content: "3";
  background-color: #efaa20 !important;
  width: 53px !important;
  height: 53px !important;
  border: 1px solid #efaa20 !important;
  color: white !important;
  font-family: "Cairo" !important;
  font-size: 30px !important;
}
.go433304200:before {
  content: "✓" !important;
  color: white;
  background-color: #efaa20 !important;
}

.AddProject .go2150698616 {
  border: unset !important;
}
